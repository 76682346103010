import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

const HomePage = ({ data }) => {
  const { edges: posts } = data.posts

  const { edges: projects } = data.projects

  return (
    <Wrapper>
      <IndexSection>
        <SectionHeadingWrap>
          <SectionHeading>
            Elliot Schultz
          </SectionHeading>
        </SectionHeadingWrap>
        <SectionContent>
          <ProminentCopy>Animation &amp; Design</ProminentCopy>
          <DefaultCopy>Melbourne, Australia</DefaultCopy>
        </SectionContent>
      </IndexSection>
      <IndexSection>
        <SectionHeadingWrap>
          <SectionHeading>
            Notes
          </SectionHeading>
        </SectionHeadingWrap>
        <SectionContent>
          {posts.map(({ node: post }) => (
            <BlogLink to={post.fields.slug} key={post.id}>
              <HoverCaption> {post.frontmatter.date}</HoverCaption>
              <h3>{post.frontmatter.title}</h3>
            </BlogLink>
          ))}
        </SectionContent>

      </IndexSection>

      {/* <IndexSection>
        <SectionHeadingWrap>
          <SectionHeading>
            Selected Work
          </SectionHeading>
        </SectionHeadingWrap>

        <SectionContent>
          {projects.map(({ node: project }) => (
            <BlogLink to={project.fields.slug} key={project.id}>
              <HoverCaption> {project.frontmatter.date}</HoverCaption>
              <h3>{project.frontmatter.title}</h3>
            </BlogLink>
          ))}
        </SectionContent>

      </IndexSection> */}

      <IndexSection>

        <SectionHeadingWrap>
          <SectionHeading>
            Connect
          </SectionHeading>
        </SectionHeadingWrap>

        <SectionContent>
          <ExternalLink href="https://instagram.com/elliotschultz" target="_blank">Instagram</ExternalLink>
          <ExternalLink href="https://twitter.com/elliotschultz" target="_blank">Twitter</ExternalLink>
          <ExternalLink href="mailto:elliot@elliotschultz.com" target="_blank">elliot@elliotschultz.com</ExternalLink>
        </SectionContent>

      </IndexSection>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 var(--gridMarginGap) calc(var(--gridMarginGap) * 3) var(--gridMarginGap);

  //margin-top: calc(50vh - 16px);
`

const HoverCaption = styled.span`
  opacity: 0;
  display: inline-block;
  position: relative;
  width: 0;
  left: -3.5em;

  pointer-events: none;
  font-variant-numeric: tabular-nums;
  font-size: var(--step-1);
`

const BlogLink = styled(Link)`
  position: relative;

  color: #000;
  font-weight: 400;

  font-size: var(--step-1-5);

  line-height: 1.2;

  display: block;

  //outline: 1px dashed pink;

  padding: calc(var(--gridMarginGap) / 4) 0;

  text-decoration: none;

  & > h3 {
    display: inline-block;
    text-decoration: underline;
  }

  &:hover > ${HoverCaption},
  &:focus > ${HoverCaption} {
    opacity: 1;
  }
`


const ExternalLink = styled.a`
  text-decoration: underline;
  color: #000;
  font-weight: 400;

  font-size: var(--step-1-5);

  line-height: 1.2;

  display: block;

  padding: calc(var(--gridMarginGap) / 4) 0;
`

const IndexSection = styled.div`
  display: grid;

  grid-template-columns: var(--gridTemplateColumnsDefault);
  column-gap: var(--gridColumnGap);

  grid-template-areas: 'a a b b b b';

  //background-color: yellow;

  padding-top: calc(var(--gridMarginGap) * 0.75);

  height: calc(45vh - 6rem);

  margin-bottom: 6rem;

  & + & {
    padding-top: 0;
    height: auto;
  }
`

const SectionHeadingWrap = styled.div`

  grid-area: a / a / a / a;
`

const SectionHeading = styled.h2`

  font-size: var(--step-1-5);

  font-weight: 500;
  line-height: calc(16/12);

  position: sticky;
  top: calc(50vh - 16px);
  top: calc(var(--gridMarginGap) * 0.75);


  padding: calc(var(--gridMarginGap) / 4) 0;
`


const SectionContent = styled.div`

  font-size: var(--step-1-5);
  line-height: 16/12;

  grid-area: b / b / b / b;
`

const ProminentCopy = styled.div`
  font-size: var(--step-1-5);
  line-height: calc(16/12);
  font-weight: 500;
  padding-top: calc(var(--gridMarginGap) / 4);
`
const DefaultCopy = styled.div`
  font-size: var(--step-1-5);
  line-height: calc(16/12);
`

export const pageQuery = graphql`
  query homeQuery {
    posts: allMdx(
      filter: { 
        fields: { postType: { eq: "post" } },
        frontmatter: { published: { eq: true } }
    },
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date(formatString: "YYYY")
          }

          fields {
            slug
          }
        }
      }
    }
    projects: allMdx(
      filter: {
        fields: { postType: { eq: "project" } }
        frontmatter: { draft: { eq: false } }
      },
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date(formatString: "YYYY")
          }

          fields {
            slug
          }
        }
      }
    }
  }
`

export default HomePage